import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { baseApi } from "../apis/baseApi";
import { InvalidEmailsModal } from "../components/modals/InvalidEmailsModal";
import { PreviewTemplateModal } from "../components/modals/PreviewTemplateModal";
import { ProtectedViewContainer } from "../components/shared/ProtectedViewContainer";

import '../styles/CampaignView.scss';

let interval;

export const CampaignView = () => {
    const history = useHistory();
    const { reference } = useParams();

    const uploadDataInputRef = useRef();
    const uploadAttachmentsInputRef = useRef();

    const [campaignStatus, setCampaignStatus] = useState({});
    const [data, setData] = useState([]);
    const [missingAttachments, setMissingAttachments] = useState([]);

    const [selectedDataFile, setSelectedDataFile] = useState(null);

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const [nextBatchCountdown, setNextBatchCountdown] = useState(0);
    const [runningStage, setRunningStage] = useState('');

    const [emailHost, setEmailHost] = useState('');
    const [emailUser, setEmailUser] = useState('');
    const [emailPass, setEmailPass] = useState('');
    const [batchSize, setBatchSize] = useState('');
    const [batchInterval, setBatchInterval] = useState('');
    const [emailSenderName, setEmailSenderName] = useState('');

    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');

    const [invalidEmails, setInvalidEmails] = useState([]);

    useEffect(() => {
        // const aux = [];
        // for (let i = 0; i < 100; i++) {
        //     aux.push(`address${i}@non`);
        // }

        // setInvalidEmails(aux);

        baseApi.get(`/campaigns/${reference}`).then(response => {
            setEmailHost(response.data.emailHost);
            setEmailSubject(response.data.emailSubject);
            setEmailBody(response.data.emailBody);
            setEmailSenderName(response.data.emailSenderName);

            setBatchSize(response.data.batchSize);
            setBatchInterval(response.data.batchInterval);

            if (response.data.templateId) {
                setSelectedTemplate(response.data.templateId);
            }
        });

        baseApi.get('/templates').then(response => {
            setTemplates(response.data.templates);
        });

        fetchData();
    }, []);

    useEffect(() => {
        console.log(emailBody);
    }, [emailBody]);

    const fetchData = () => {
        baseApi.get(`/campaigns/${reference}/data`).then(response => {
            setData(response.data.data);
            setMissingAttachments(response.data.missingAttachments);
        });

        baseApi.get(`/campaigns/${reference}/status`).then(response => {
            setCampaignStatus(response.data);
        });
    };

    const updateCampaignAttribute = (attrName) => {
        return () => {
            let value = '';

            switch (attrName) {
                case 'emailHost': value = emailHost; break;
                case 'emailSubject': value = emailSubject; break;
                case 'emailBody': value = emailBody; break;
                case 'batchSize': value = batchSize; break;
                case 'batchInterval': value = batchInterval; break;
                case 'emailSenderName': value = emailSenderName ; break;
            }

            baseApi.patch(`/campaigns/${reference}`, { [attrName]: value });
        }
    }

    const run = () => {
        setRunningStage('batch');
        baseApi.post(`/campaigns/${reference}/run-batch`, {
            emailUser,
            emailPassword: emailPass,
        }).then(response => {
            setRunningStage('waiting_next_batch');
            fetchData();

            if (response.data.pendingCounter > 0) {
                let _nextBatchCountdown = +batchInterval;
                setNextBatchCountdown(_nextBatchCountdown);

                interval = setInterval(() => {
                    _nextBatchCountdown--;
                    if (_nextBatchCountdown === 0) {
                        clearInterval(interval);
                        return run();
                    }
                    
                    setNextBatchCountdown(_nextBatchCountdown);
                }, 1000);
            }
            else {
                setRunningStage('');
            }
        }).catch(err => {
            setRunningStage('');
            window.alert(err.response.data.message);
        });
    };

    const runBtn_Click = () => {
        run();
    };

    const stopBtn_Click = () => {
        clearInterval(interval);
        setNextBatchCountdown(0);
        setRunningStage('');
    };

    const goBackBtn_Click = () => {
        history.goBack();
    };

    const uploadDataBtn_Click = () => {
        uploadDataInputRef.current.click();
    };

    const uploadDataBtn_Change = (file, forceUpload = false) => {
        const formData = new FormData();
        formData.set('data', file);
        formData.set('allowInvalidEmails', forceUpload);

        baseApi.post(`/campaigns/${reference}/data`, formData).then(response => {
            if (response.status === 200) {
                fetchData();
                setSelectedDataFile(null);
            }
        }).catch(({ response }) => {
            if (response.status === 400 && response.data.message === 'invalid_email_addresses') {
                console.log(response.data.invalidEmailAddresses);
                setInvalidEmails(response.data.invalidEmailAddresses);
            }
            else if (response.status === 400) {
                window.alert(response.data.message);
            }
        });
    };

    const uploadAttachmentsBtn_Click = () => {
        uploadAttachmentsInputRef.current.click();  
    };

    const uploadAttachmentsBtn_Change = (file) => {
        const formData = new FormData();
        formData.set('attachments', file);

        baseApi.post(`/campaigns/${reference}/attachments`, formData).then(response => {
            if (response.status === 200) {
                fetchData();
                window.alert('El comprimido de adjuntos se subió correctamente.');
            }
        }).catch(err => {
            console.error(err);
            window.alert('Ocurrió un error al subir el archivo.');
        });
    };

    const templateSelect_Change = (e) => {
        setSelectedTemplate(e.currentTarget.value);

        const templateId = e.currentTarget.value !== '0'
            ? e.currentTarget.value
            : null;

        baseApi.patch(`/campaigns/${reference}`, { templateId });
    };

    const cancelDataUploadBtn_Click = () => {
        setInvalidEmails([]);
        setSelectedDataFile(null);
    };

    const forceDataUploadBtn_Click = () => {
        setInvalidEmails([]);
        uploadDataBtn_Change(selectedDataFile, true);
    };

    const deleteCampaignBtn_Click = () => {
        if (window.confirm('**** ATENCIÓN: ¿CONFIRMAR LA ELIMINACIÓN DE ESTA CAMPAÑA? ****')) {
            baseApi.delete(`/campaigns/${reference}`).then(response => {
                history.push('/campaigns');
            });
        }
    }

    return (
        <ProtectedViewContainer>
            <div id="campaign-view">
                <div className="status-bar">
                    <div>
                        <div className="button" onClick={goBackBtn_Click}>
                            <img src={require('../assets/return-icon.png')} alt="" />
                            Regresar
                        </div>
                    </div>
                    <div>

                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="progress">
                            <div className="no-execution" style={{ display: runningStage === '' ? 'flex' : 'none' }}>
                                Actualmente no hay ningún proceso en ejecución.
                            </div>
                            <div className="next-batch-countdown" style={{ display: runningStage === 'waiting_next_batch' ? 'flex' : 'none' }}>
                                Siguente lote en {nextBatchCountdown} segundos...
                            </div>
                            <div className="batch-running" style={{ display: runningStage === 'batch' ? 'flex' : 'none' }}>
                                Lote en ejecución...
                            </div>
                        </div>
                        <div className="button" onClick={runBtn_Click} style={{ display: runningStage === '' ? 'flex' : 'none' }}>
                            <img src={require('../assets/play-icon.png')} alt="" />
                            Correr campaña
                        </div>
                        <div className="button" onClick={stopBtn_Click} style={{ display: runningStage === '' ? 'none' : 'flex' }}>
                            <img src={require('../assets/stop-icon.png')} alt="" />
                            Detener ejecución
                        </div>
                        <div className="button" onClick={deleteCampaignBtn_Click} style={{ display: 'flex', marginLeft: '10px' }}>
                            <img src={require('../assets/delete.png')} alt="" />
                            Borrar campaña
                        </div>
                    </div>
                </div>
                <div className="email-account">
                    <div className="email-account-field">
                        <p>Host de la cuenta de correo</p>
                        <input type="text" value={emailHost} onChange={e => setEmailHost(e.currentTarget.value)} onBlur={updateCampaignAttribute('emailHost')} />
                    </div>
                    <div className="email-account-field">
                        <p>Usuario de la cuenta de correo</p>
                        <input type="text" value={emailUser} onChange={e => setEmailUser(e.currentTarget.value)} />
                    </div>
                    <div className="email-account-field">
                        <p>Contraseña de la cuenta de correo</p>
                        <input type="password" value={emailPass} onChange={e => setEmailPass(e.currentTarget.value)} />
                    </div>
                    <div className="email-account-field">
                        <p>Intervalo de tiempo entre cada lote de ejecución (segundos)</p>
                        <input type="text" value={batchInterval} onChange={e => setBatchInterval(e.currentTarget.value)} onBlur={updateCampaignAttribute('batchInterval')} />
                    </div>
                    <div className="email-account-field">
                        <p>Cantidad de correos a enviar por lote</p>
                        <input type="text" value={batchSize} onChange={e => setBatchSize(e.currentTarget.value)} onBlur={updateCampaignAttribute('batchSize')} />
                    </div>
                    <div className="email-account-field">
                        <p>Nombre del remitente</p>
                        <input type="text" value={emailSenderName} onChange={e => setEmailSenderName(e.currentTarget.value)} onBlur={updateCampaignAttribute('emailSenderName')} />
                    </div>
                </div>
                <div className="email-contents">
                    <input type="text" placeholder="Asunto del correo" value={emailSubject} onChange={e => setEmailSubject(e.currentTarget.value)} onBlur={updateCampaignAttribute('emailSubject')} />
                    <textarea placeholder="Texto del correo" value={emailBody} onChange={e => setEmailBody(e.currentTarget.value)} onBlur={updateCampaignAttribute('emailBody')}></textarea>
                    <div className="template-select-container">
                        Plantilla del correo
                        <select value={selectedTemplate} onChange={templateSelect_Change}>
                            <option value="0">(Sin plantilla)</option>
                            {templates?.map(t => <option value={t.id}>{t.title}</option>)}
                        </select>
                        <div id="template-preview-btn" className="button" onClick={() => setShowPreviewModal(true)}>
                            <img src={require('../assets/preview-icon.png')} alt="" />
                            Previsualizar
                        </div>
                    </div>
                </div>
                <div className="data">
                    <div style={{ display: 'flex' }}>
                        <div className="button" style={{ marginRight: '10px' }} onClick={uploadDataBtn_Click}>
                            <img src={require('../assets/data-icon.png')} alt="" />
                            Subir CSV de información
                        </div>
                        <div className="button" onClick={uploadAttachmentsBtn_Click}>
                            <img src={require('../assets/attachments-icon.png')} alt="" />
                            Subir ZIP con archivos adjuntos
                        </div>
                    </div>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={uploadDataInputRef}
                        accept=".csv"
                        onChange={e => {
                            setSelectedDataFile(e.currentTarget.files[0])
                            uploadDataBtn_Change(e.currentTarget.files[0]);
                        }}
                    />
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={uploadAttachmentsInputRef}
                        accept=".zip"
                        onChange={e => uploadAttachmentsBtn_Change(e.currentTarget.files[0])}
                    />
                    <div className="emails-tbl-container">
                        <table className="emails-tbl">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Correo(s) electrónico(s)</th>
                                    {data?.[0]?.fields && Object.keys(data?.[0]?.fields).map(field => {
                                        return <th>{field}</th>
                                    })}
                                    <th>Archivos adjuntos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map(row => {
                                    return <tr>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className={['status', row.status].join(' ')}></div>
                                        </td>
                                        <td>{row.emailAddresses.split(';').map(email => <div>{email}</div>)}</td>
                                        {Object.keys(row.fields).map(field => {
                                            return <td>{row.fields[field]}</td>
                                        })}
                                        {row.attachments.length > 0 && <td className="attachments">
                                            {row.attachments.map(att => <div className={[missingAttachments.includes(att) && 'missing'].join(' ')}>{att}<br /></div>)}
                                        </td> || <td>N/A</td>}
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="logs">
                        <div className="status">
                            <div className="status-item">
                                <div className="status-item-label">Correos enviados exitosamente</div>
                                <div className="status-item-counter">{campaignStatus.sent || '0'}</div>
                            </div>
                            <div className="status-item">
                                <div className="status-item-label">Correos pendientes</div>
                                <div className="status-item-counter">{campaignStatus.pending || '0'}</div>
                            </div>
                            <div className="status-item">
                                <div className="status-item-label">Correos con error</div>
                                <div className="status-item-counter">{campaignStatus.error || '0'}</div>
                            </div>
                            <div className="status-item">
                                <div className="status-item-label">Correos totales</div>
                                <div className="status-item-counter">{campaignStatus.total || '0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PreviewTemplateModal
                isVisible={showPreviewModal}
                htmlTemplate={templates.find(t => t.id === +selectedTemplate)?.content}
                emailBody={emailBody}
                closeBtn_Click={() => setShowPreviewModal(false)}
            />
            <InvalidEmailsModal
                isVisible={invalidEmails?.length > 0}
                emailAddresses={invalidEmails}
                cancelUploadEvent={cancelDataUploadBtn_Click}
                forceUploadEvent={forceDataUploadBtn_Click}
            />
        </ProtectedViewContainer>
    )
}